.account {
    margin: 10px auto;
    .transfer-settings {
        .transfer-setting {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            box-shadow: 0px 0px 3px rgba(107, 107, 107, 0.5);

            > div:first-child {
                border-radius: 5px 5px 0 0;
                padding: 10px;
                flex-grow: 1;
                > * {
                    margin-bottom: 5px;
                }
                h4 {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
            > div:last-child {
                line-height: 35px;
                color: #fff;
                text-align: center;
                border-radius: 0 5px 5px 0;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .spinner {
                    height: 20px;
                    width: 20px;
                }
                img {
                    margin: 0 10px;
                    height: 30px;
                }
            }
        }
    }
}
